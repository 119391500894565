(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.vehicles.view.controller:VehicleViewCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.view')
    .controller('VehiclesViewCtrl', VehiclesViewCtrl);

  function VehiclesViewCtrl($filter, $stateParams, $mdDialog, Restangular, event) {
    var vm = this;

    vm.event = event.data;
    vm.vehicle = {};
    vm.vehicle_stats = {};
    vm.editPerson = editPerson;
    vm.editSafety = editSaftey;

    Restangular.one('vehicles', $stateParams.vehicleId).get({inc: 'Guests,VehiclePeople,VehiclePeople.Person'}).then(function (response) {
      vm.vehicle = response.data;

      // Cents to dollars
      var i = 0;
      for (i = 0; i < vm.vehicle.vehicle_people.length; i++) {
        vm.vehicle.vehicle_people[i].meal_cost = $filter('dollars')(vm.vehicle.vehicle_people[i].meal_cost);
        vm.vehicle.vehicle_people[i].option = $filter('dollars')(vm.vehicle.vehicle_people[i].option);
      }
      for (i = 0; i < vm.vehicle.guests.length; i++) {
        vm.vehicle.guests[i].cost = $filter('dollars')(vm.vehicle.guests[i].cost);
      }
    });

    refreshStats();

    function refreshStats() {

      // Get vehicle stats asynchronously in case it takes a while
      Restangular.one('vehicles', $stateParams.vehicleId).one('vehicle-stats').get().then(function (response) {
        vm.vehicle_stats = response.data[0];

        // Cents to dollars
        vm.vehicle_stats.dinner_owing = $filter('dollars')(vm.vehicle_stats.dinner_owing);
        vm.vehicle_stats.dinner_paid = $filter('dollars')(vm.vehicle_stats.dinner_paid);
        vm.vehicle_stats.donations_owing = $filter('dollars')(vm.vehicle_stats.donations_owing);
        vm.vehicle_stats.donations_paid = $filter('dollars')(vm.vehicle_stats.donations_paid);
        vm.vehicle_stats.entry_owing = $filter('dollars')(vm.vehicle_stats.entry_owing);
        vm.vehicle_stats.entry_paid = $filter('dollars')(vm.vehicle_stats.entry_paid);
        vm.vehicle_stats.meals_owing = $filter('dollars')(vm.vehicle_stats.meals_owing);
        vm.vehicle_stats.meals_paid = $filter('dollars')(vm.vehicle_stats.meals_paid);
        vm.vehicle_stats.option_owing = $filter('dollars')(vm.vehicle_stats.option_owing);
        vm.vehicle_stats.option_paid = $filter('dollars')(vm.vehicle_stats.option_paid);
      });
    }

    function editPerson(ev, vehiclePerson, event) {
      var orig = angular.copy(vehiclePerson);
      $mdDialog.show({
        controller: EditPersonController,
        templateUrl: 'events/vehicles/view/edit-person.tpl.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          vehiclePerson: vehiclePerson,
          event: event
        }
      }).then(function () {
      }, function cancelled() {

        // Revert data
        vehiclePerson.position = orig.position;
        vehiclePerson.meal_cost = orig.meal_cost;
        vehiclePerson.option = orig.option;
        vehiclePerson.indemnified = orig.indemnified;
      });
    }

    function editSaftey(ev, vehicle) {
        var orig = angular.copy(vehicle);
        $mdDialog.show({
            controller: EditSafetyController,
            templateUrl: 'events/vehicles/view/edit-saftey.tpl.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            locals: {
                vehicle: vehicle
            }
        }).then(function () {
        }, function cancelled() {

            // Revert data
            vehicle.safety_ok = orig.safety_ok;
            vehicle.safety_notes = orig.safety_notes;
        });
    }

    function EditPersonController($scope, $mdDialog, vehiclePerson, event) {

      $scope.vehiclePerson = vehiclePerson;
      $scope.event = event;

      $scope.cancel = cancel;
      $scope.confirmRemoveFromVehicle = confirmRemoveFromVehicle;
      $scope.submit = submit;

      function cancel() {
        $mdDialog.cancel();
      }

      function submit() {
        submitVehiclePerson(vehiclePerson);
      }
    }

    function EditSafetyController($scope, $mdDialog, vehicle) {

      $scope.vehicle = vehicle;

      $scope.cancel = cancel;
      $scope.submit = submit;

      function cancel() {
        $mdDialog.cancel();
      }

      function submit() {
        submitVehicleSafety(vehicle);
      }
    }

    function confirmRemoveFromVehicle(ev, vehiclePerson) {

      var name = $filter('name')(vehiclePerson.person);

      var confirm = $mdDialog.confirm()
        .title('Confirm removal')
        .textContent('Remove ' + name + ' from vehicle ' + vm.vehicle.number + '?')
        .targetEvent(ev)
        .ok('OK')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        removeFromVehicle(vehiclePerson);
      });
    }

    function removeFromVehicle(vehiclePerson) {
      Restangular.one('vehicle-people', vehiclePerson.id).remove().then(function () {

        // Remove from list
        var vp;
        for (var i = 0; i < vm.vehicle.vehicle_people.length; i++) {
          vp = vm.vehicle.vehicle_people[i];
          if (vp.id === vehiclePerson.id) {
            vm.vehicle.vehicle_people.splice(i, 1);
          }
        }

      }, function (err) {
        console.log('delete failed', err);
      });
    }

    function submitVehiclePerson(vehiclePerson) {

      var data = angular.copy(vehiclePerson);

      // Dollars to cents
      data.meal_cost = $filter('cents')(vehiclePerson.meal_cost);
      data.option = $filter('cents')(vehiclePerson.option);

      data.participation = parseInt(vehiclePerson.participation, 10)

      Restangular.one('vehicle-people', vehiclePerson.id).customPUT(data).then(submitVehiclePersonSuccess, submitVehiclePersonFailure);
    }

    function submitVehicleSafety(vehicle) {

      var data = {
          safety_ok: vehicle.safety_ok,
          safety_notes: vehicle.safety_notes
      };

      Restangular.one('vehicles', vehicle.id).one('safety').customPUT(data).then(submitVehicleSafetySuccess, submitVehicleSafetyFailure);
    }

    function submitVehiclePersonSuccess() {
      $mdDialog.hide();
      refreshStats();
    }

    function submitVehiclePersonFailure(err) {
      console.log('failed', err);
      $mdDialog.cancel();
      $mdDialog.alert()
        .title('Error')
        .textContent('Something went wrong!');
    }

    function submitVehicleSafetySuccess() {
      $mdDialog.hide();
    }

    function submitVehicleSafetyFailure(err) {
      console.log('failed', err);
      $mdDialog.cancel();
      $mdDialog.alert()
        .title('Error')
        .textContent('Something went wrong!');
    }
  }
}());
